// Pagination.js
import React from "react";
import { Box, Button, Text } from "grommet";

const PaginationControls = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  setItemsPerPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      pad={{ vertical: "small" }}
      flex={false}
      margin={{ top: "medium" }}
      border="top"
    >
      <Text margin="small">
        Showing {startItem}-{endItem} of {totalItems} items
      </Text>
      <Box direction="row" align="center" gap="small">
        <Text margin="xxsmall">Items per page</Text>
        <select
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(Number(e.target.value))}
          style={{ pad: "large" }}
        >
          {[10, 20, 50, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <Button
          label="<"
          onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, index) => (
          <Button
            key={index + 1}
            label={index + 1}
            onClick={() => onPageChange(index + 1)}
            primary={currentPage === index + 1}
          />
        ))}
        <Button
          label=">"
          onClick={() =>
            onPageChange(
              currentPage < totalPages ? currentPage + 1 : totalPages
            )
          }
          disabled={currentPage === totalPages}
        />
      </Box>
    </Box>
  );
};

export default PaginationControls;
