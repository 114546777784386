import React from "react";
import { Box, Text, Heading } from "grommet";

const FormatResponse = (response) => {
  const lines = response.split("\n").filter((line) => line.trim() !== "");

  return (
    <Box>
      {lines.map((line, index) => {
        // Check for different formatting patterns
        const isHeading4 = line.startsWith("###");
        const isPrimaryBullet = line.startsWith("•");
        const isSecondaryBullet = line.startsWith("* ");
        const isNumbered = /^\d+\./.test(line);

        // Split line and apply bold formatting
        const formattedLine = line.split(/(\*\*[^*]+\*\*)/).map((part, i) => {
          // Check if part is bold (wrapped in **)
          if (/^\*\*[^*]+\*\*$/.test(part)) {
            return <strong key={i}>{part.slice(2, -2)}</strong>;
          }
          return <React.Fragment key={i}>{part}</React.Fragment>;
        });

        if (isHeading4) {
          const headingText = line.replace(/^###\s*/, "");
          return (
            <Heading key={index} level={2} margin={{ vertical: "small" }}>
              {headingText}
            </Heading>
          );
        }

        if (isPrimaryBullet) {
          return (
            <Box key={index} direction="row" margin={{ vertical: "xxsmall" }}>
              <Text as="span" margin={{ right: "small" }}>
                •
              </Text>
              <Text as="span">{formattedLine}</Text>
            </Box>
          );
        }

        if (isSecondaryBullet) {
          // Remove the secondary bullet symbol (*) from the line text
          const secondaryText = line.replace(/^\*\s*/, "");
          return (
            <Box
              key={index}
              direction="row"
              margin={{ left: "medium", vertical: "xxsmall" }}
            >
              <Text as="span" margin={{ right: "small" }}>
                •
              </Text>
              <Text as="span">{secondaryText}</Text>{" "}
              {/* Display text without '*' */}
            </Box>
          );
        }
        if (isNumbered) {
          // Extract the number and remove it from the line text
          const numberText = line.match(/^\d+\./)[0];
          const textWithoutNumber = line.replace(/^\d+\.\s*/, ""); // Remove number from text

          return (
            <Box key={index} direction="row" margin={{ vertical: "xxsmall" }}>
              <Text as="span" margin={{ right: "small" }}>
                {numberText}
              </Text>
              <Text as="span">{textWithoutNumber}</Text>{" "}
              {/* Display text without number */}
            </Box>
          );
        }

        return (
          <Text key={index} textAlign="start" margin={{ vertical: "xxsmall" }}>
            {formattedLine}
          </Text>
        );
      })}
    </Box>
  );
};

export default FormatResponse;
