import React from "react";
import { Layer, Box, Text, Button, Anchor, InfiniteScroll } from "grommet";
import ChatWindow from "../components/ChatWindow"; // Import ChatWindow component
import FormatResponse from "../components/FormatResponse";

const ContractAnalysisDrawer = ({
  onClose,
  contractId,
  analysisResult,
  analysisSources,
  size,
  contractCollectionName,
  chatHistory,
}) => {
  // Combine analysisResult and analysisSources into a single array
  const combinedItems = [
    { type: "analysis", content: analysisResult },
    ...(analysisSources || []).map((source) => ({
      type: "source",
      content: source,
    })),
  ];

  return (
    <Layer
      position="right"
      full={!["xsmall", "small"].includes(size) ? "vertical" : true}
      onEsc={onClose}
      // onClickOutside={onClose}
      width={["medium", "large"].includes(size) ? "full" : "50%"}
    >
      <Box
        fill="vertical"
        width="xlarge"
        pad="medium"
        // margin={{ bottom: "small" }}
      >
        <Box flex={false} direction="row" justify="between" align="center">
          <Box direction="row" gap="small" align="center">
            <Text weight="bold" size="large">
              Contract Analysis:
            </Text>
            <Text
              weight="bold"
              size="large"
              style={{ textDecoration: "underline" }}
            >
              Contract ID{" "}
              <span style={{ textDecoration: "underline" }}>{contractId}</span>
            </Text>
          </Box>
          <Button label="Close" onClick={onClose} secondary />
        </Box>

        {/* Combined Analysis and Sources Section with InfiniteScroll */}
        <Box height="45vh" overflow="auto" >
          <InfiniteScroll items={combinedItems}>
            {(item, index) => (
              <Box key={index} flex={false} >
                {item.type === "analysis" ? (
                  <Text>{FormatResponse(item.content)}</Text>
                ) : (
                  <>
                    {/* Only render Sources label once */}
                    {index === 1 && (
                      <Text
                        weight="bold"
                        size="medium"
                        margin={{ top: "small" }}
                      >
                        Sources:
                      </Text>
                    )}
                    <Anchor
                      href={item.content}
                      label={item.content}
                      target="_blank"
                    />
                  </>
                )}
              </Box>
            )}
          </InfiniteScroll>
        </Box>

        {/* ChatWindow Section */}
        <Box
          fill
          margin={{ top: "small" }}
          flex={{ grow: 0, shrink: 1, basis: "auto" }}
        >
          <ChatWindow
            contractCollectionName={contractCollectionName}
            chatHistory={chatHistory}
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default ContractAnalysisDrawer;
