import React from "react";
import { Header, Box, Image, Text } from "grommet";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { PiSignOutBold } from "react-icons/pi";
import { Tooltip } from "react-tooltip";
import HpeLogo from "./logo.svg";
import "./Navbar.css";

function AppHeader() {
  return (
    <Header
      pad="small"
      sticky="top"
      border={{ side: "bottom", color: "light-4", size: "small" }}
    >
      {/* Left side logo */}
      <Box direction="row" align="center" gap="xsmall">
        <Image src={HpeLogo} alt="HPE Logo" className="logo-image" />
      </Box>

      {/* Centered brand name */}
      <Box flex align="center" justify="center">
        <Text className="brand-name" weight="bold">Ask Bob: Contract Assistant</Text>
      </Box>

      {/* Right side sign out icon */}
      <Box direction="row" align="center" gap="small">
        <Authenticator>
          {({ signOut, user }) => (
            <Box direction="row" align="center">
              <Tooltip id="signout-tooltip" style={{ padding: "5px" }} />
              <PiSignOutBold
                data-tooltip-id="signout-tooltip"
                data-tooltip-content="Sign out"
                data-tooltip-place="bottom"
                onClick={signOut}
                className="sign-out-icon"
              />
            </Box>
          )}
        </Authenticator>
      </Box>
    </Header>
  );
}

export default AppHeader;
