import React, { useState, useContext, useEffect, useCallback } from "react";
import { Box, DataTable, ResponsiveContext, Text, Button } from "grommet";
import {
  StatusGoodSmall,
  StatusWarningSmall,
  StatusCriticalSmall,
  StatusUnknownSmall,
  Refresh,
} from "grommet-icons";
import PaginationControls from "../components/Pagination";
import TableControls from "../components/TableControls";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing

export const Reports = () => {
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [totalItems, setTotalItems] = useState(0); // New state for total items

  // Utility function to capitalize text and remove underscores
  const capitalize = (str) =>
    str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

  // Function to render status column with icons
  const statusRenderer = (status) => {
    let icon;
    switch (status) {
      case "completed":
        icon = <StatusGoodSmall color="status-ok" />;
        break;
      case "in process":
        icon = <StatusUnknownSmall color="status-unknown" />;
        break;
      case "pending":
        icon = <StatusWarningSmall color="status-warning" />;
        break;
      default:
        icon = <StatusCriticalSmall color="status-critical" />;
        break;
    }
    return (
      <Box direction="row" align="center" gap="small">
        {icon}
        <Text>{status}</Text>
      </Box>
    );
  };

  // Handle report click event
  const handleReportClick = useCallback(
    (reportId) => {
      navigate(`/report/${reportId}`); 
    },
    [navigate]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://d3cwadfonj.execute-api.us-west-2.amazonaws.com/dev/contract-analysis/tasks?limit=20"
        );
        const result = await response.json();
        console.log(result); 

        // Check if result has the expected structure and total_count is available
        if (!result || !Array.isArray(result.tasks)) {
          throw new Error("Invalid response structure");
        }

        const { tasks, total_count } = result;

        // Set the total count for pagination if available
        if (total_count) {
          setTotalItems(total_count); 
        } else {
          setTotalItems(tasks.length);
        }

        if (Array.isArray(tasks) && tasks.length > 0) {
          const generatedColumns = Object.keys(tasks[0])
            .filter((key) => key !== "total_count") // Exclude total_count from columns
            .map((key) => {
              // Define columns similarly as before
              if (key === "report_id") {
                return {
                  property: key,
                  header: (
                    <Text style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                      {capitalize(key)}
                    </Text>
                  ),
                  render: (datum) => (
                    <Text
                      color="brand"
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() => handleReportClick(datum[key])}
                    >
                      {datum[key]}
                    </Text>
                  ),
                  align: "start",
                };
              }

              if (key === "contract_ids") {
                return {
                  property: key,
                  header: (
                    <Text style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                      {capitalize(key)}
                    </Text>
                  ),
                  render: (datum) => <Text>{datum[key].join(", ")}</Text>,
                  align: "start",
                };
              }

              if (key === "analysis_question") {
                return {
                  property: key,
                  header: (
                    <Text style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                      {capitalize(key)}
                    </Text>
                  ),
                  render: (datum) => <Text>{datum[key]}</Text>,
                  align: "start",
                  size: "350px",
                };
              }

              if (key === "status") {
                return {
                  property: key,
                  header: capitalize(key),
                  render: (datum) => statusRenderer(datum[key]),
                  align: "start",
                };
              }

              if (key === "error") {
                return {
                  property: key,
                  header: (
                    <Text style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                      {capitalize(key)}
                    </Text>
                  ),
                  render: (datum) => <Text>{datum[key]}</Text>,
                  align: "start",
                  size: "200px",
                };
              }

              return {
                property: key,
                header: (
                  <Text style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    {capitalize(key)}
                  </Text>
                ),
                render: (datum) => <Text>{datum[key]}</Text>,
                align: "start",
              };
            });

          // Ensure report_id column is the first one
          const reportIdColumn = generatedColumns.find(
            (col) => col.property === "report_id"
          );
          const otherColumns = generatedColumns.filter(
            (col) => col.property !== "report_id"
          );
          setColumns([reportIdColumn, ...otherColumns]);

          setData(tasks);
          setFilteredData(tasks);
        } else {
          console.warn("No tasks found or tasks is not an array.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [handleReportClick]);

  // Handle search functionality
  const onSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);

    const filtered = data.filter((record) => {
      if (selectedColumn) {
        const recordValue = String(record[selectedColumn]);
        return recordValue.toLowerCase().includes(value.toLowerCase());
      } else {
        return Object.values(record).some((val) =>
          String(val).toLowerCase().includes(value.toLowerCase())
        );
      }
    });

    setFilteredData(filtered);
  };

  // Paginate filtered data
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change in pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Box>
      <Box margin="medium">
        <Box
          direction="row"
          justify="between"
          pad={{ vertical: "small" }}
          gap="medium"
          margin={{ left: "auto" }}
        >
          {/* Refresh button at the right end */}
          <Button
            icon={<Refresh />}
            label="Refresh"
            onClick={() => window.location.reload()} // Reloads the page on click
            primary
          />
        </Box>
        <TableControls selected={selected} data={paginatedData} />
        {/* DataTable to display paginated data with custom columns */}
        <Box overflow="auto">
          <DataTable
            aria-describedby="orders-heading"
            data={paginatedData}
            primaryKey="report_id" // Use report_id as the primary key
            columns={columns}
            pin={["xsmall", "small"].includes(size)}
            select={selected}
            onSelect={setSelected}
          />
        </Box>
        {/* // Pagination controls with totalItems from total_count */}
        <PaginationControls
          currentPage={currentPage}
          totalItems={totalItems} // totalItems comes from total_count or tasks length
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          setItemsPerPage={setItemsPerPage}
        />
      </Box>
    </Box>
  );
};

export default Reports;
