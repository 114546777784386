import React, { useState } from "react";
import { Box, Button, Layer, Heading, Text, TextInput } from "grommet";

const ReportInfo = ({ onClose, selectedContracts, selectedAnalysis }) => {
  const [reportName, setReportName] = useState("");
  const [userQuestion, setUserQuestion] = useState("");

  const handleRunAnalysis = async () => {
    console.log("Selected Contract IDs:", selectedContracts);
    console.log("Selected Analysis Type:", selectedAnalysis);
    console.log("Report Name:", reportName);
    console.log("User Question:", userQuestion);

    let analysisType;
    if (selectedAnalysis === "Natural Language Processing") {
      analysisType = "NATURAL_LANGUAGE";
    } else if (selectedAnalysis === "Advanced Programmatic Prompt") {
      analysisType = "ADVANCE_PROMPT"; // Adjust this to the correct value
    } else if (selectedAnalysis === "Deviation Analysis") {
      analysisType = "DEVIATION"; // Handle deviation analysis
    } else {
      return; // Exit if the analysis type is unknown
    }

    const requestData = {
      contract_ids: selectedContracts,
      analysis_type: analysisType,
      analysis_name: reportName,
      analysis_question:
        selectedAnalysis === "Natural Language Processing" ||
        selectedAnalysis === "Advanced Programmatic Prompt"
          ? userQuestion
          : undefined, // For deviation analysis, userQuestion will be undefined
    };

    try {
      const response = await fetch(
        "https://d3cwadfonj.execute-api.us-west-2.amazonaws.com/dev/contract-analysis/tasks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Analysis Task Created:", result);

      onClose();
    } catch (error) {
      console.error("Failed to run analysis:", error);
    }
  };

  // Dynamically set title based on selected analysis type
  const getTitle = () => {
    switch (selectedAnalysis) {
      case "Natural Language Processing":
        return "Natural Language Analysis";
      case "Advanced Programmatic Prompt":
        return "Advanced Programmatic Prompt";
      default:
        return "Report Info";
    }
  };

  return (
    <Layer onEsc={onClose}>
      <Box pad="medium" gap="medium" width="large">
        <Heading level={3} margin="none" weight="bold" pad="medium">
          {getTitle()}
        </Heading>

        <Box>
          <Text margin={{ bottom: "small" }}>Report Name</Text>
          <TextInput
            value={reportName}
            onChange={(event) => setReportName(event.target.value)}
            placeholder="Enter report name"
          />
        </Box>
        {(selectedAnalysis === "Natural Language Processing" ||
          selectedAnalysis === "Advanced Programmatic Prompt") && (
          <Box>
            <Text margin={{ bottom: "small" }}>
              {selectedAnalysis === "Advanced Programmatic Prompt"
                ? "Write Your Prompt"
                : "Write Your Question"}
            </Text>
            <textarea
              value={userQuestion}
              onChange={(event) => setUserQuestion(event.target.value)}
              placeholder={
                selectedAnalysis === "Advanced Programmatic Prompt"
                  ? "Write your prompt "
                  : "Type your question"
              }
              style={{
                width: "100%",
                height:
                  selectedAnalysis === "Advanced Programmatic Prompt"
                    ? "150px"
                    : "100px",
                resize: "vertical",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </Box>
        )}

        <Box
          direction="row"
          gap="medium"
          justify="between"
          margin={{ top: "medium" }}
        >
          <Button label="Cancel" onClick={onClose} />
          <Button label="Run Analysis" primary onClick={handleRunAnalysis} />
        </Box>
      </Box>
    </Layer>
  );
};

export default ReportInfo;
