import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  TextArea,
  Text,
  Spinner,
  Notification,
  Anchor,
  Tip,
  List,
} from "grommet";
import { Clipboard, Send, List as ListIcon } from "grommet-icons";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { duotoneSpace as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import FormatResponse from "./FormatResponse";
import "./ChatWindow.css";

const ChatWindow = ({ contractCollectionName, chatHistory }) => {
  const [messages, setMessages] = useState([]);
  const [textAreaMessage, setTextAreaMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendingError, setSendingError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [visibleSources, setVisibleSources] = useState({});
  const [answerContext, setAnswerContext] = useState([]);

  const scrollableDiv = useRef(null);
  console.log("-----------------------", contractCollectionName);

  useEffect(() => {
    // Automatically scroll to the bottom whenever a new message is added
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTop = scrollableDiv.current.scrollHeight;
    }
  }, [messages]); 

  const chatURL =
    "http://a1e5dd42830a548d79c7eec7b1f787bc-1179582242.us-east-1.elb.amazonaws.com/chat/post"; // Update this URL with your actual API endpoint

  const renderMarkdown = (response) => (
    <ReactMarkdown
      children={response}
      components={{
        code({ inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter style={style} language={match[1]} {...props}>
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        p: (props) => <Text {...props} margin={{ vertical: "xsmall" }} />,
        li: (props) => (
          <Text as="li" {...props} margin={{ vertical: "xsmall" }} />
        ),
        pre: (props) => (
          <Box
            as="pre"
            background="light-2"
            pad="small"
            round="xsmall"
            {...props}
          />
        ),
      }}
    />
  );

  const handleTextArea = (e) => {
    setTextAreaMessage(e.target.value);
  };

  const [isFirstMessage, setIsFirstMessage] = useState(true);

  const handleSendMessage = async () => {
    if (!contractCollectionName) {
      setSendingError("Collection name is required.");
      setShowNotification(true);
      return;
    }

    setLoading(true); // Show the loading spinner
    setSendingError(null); // Clear any previous error

    const newMessage = {
      id: uuidv4(),
      message: textAreaMessage,
      sender: "user",
    };

    // Extract the last chat history from messages
    const lastBotJson = messages.filter((msg) => msg.sender === "bot");
    const chatHistoryLastJson =
      lastBotJson[lastBotJson.length - 1]?.chat_history || "";

    console.log("=======================", chatHistoryLastJson);

    // Prepare data for sending
    let data = {
      message: newMessage.message,
      collection_name: contractCollectionName,
    };

    // Use the chatHistory prop only for the initial message
    if (isFirstMessage && chatHistory) {
      data.chat_history = chatHistory;
      setIsFirstMessage(false); // Reset the flag after the first message
    } else if (chatHistoryLastJson !== "") {
      data.chat_history = chatHistoryLastJson;
    }

    console.log("=======================", data.chat_history);

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setTextAreaMessage(""); // Clear the textarea

    try {
      const response = await axios.post(chatURL, data);

      if (
        response.data.error &&
        response.data.error === "collection not found"
      ) {
        throw new Error(
          "Collection not found. Please check your collection name."
        );
      }

      const botResponse = response.data.bot_response;
      const updatedChatHistory = response.data.chat_history || "";
      const answerContext = response.data.answer_context || [];

      const botMessage = {
        id: uuidv4(),
        message: botResponse.includes("```")
          ? renderMarkdown(botResponse)
          : FormatResponse(botResponse),
        sender: "bot",
        original_answer: botResponse,
        chat_history: updatedChatHistory,
        answer_context: answerContext,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setAnswerContext(answerContext);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.error || "An error occurred. Please try again."
          : error.message || "An unexpected error occurred.";

      setSendingError(errorMessage);
      setShowNotification(true); // Show the notification
      console.error("Error:", error);
    } finally {
      setLoading(false); // Hide the loading spinner
    }
  };

  const handleCopy = (message, messageId) => {
    navigator.clipboard.writeText(message);
    setCopiedMessageId(messageId);
    setTimeout(() => setCopiedMessageId(null), 2000);
  };

  const toggleSources = (messageId) => {
    setVisibleSources((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }));
  };

  return (
    <Box
      flex="grow"
      background="light-1"
      direction="column"
      round="small"
      overflow="auto"
      border={{
        color: "rgb(1, 169, 130)",
        borderRadius: "medium",
        size: "medium",
      }}
    >
      <Box height="medium" ref={scrollableDiv} flex="grow" overflow="auto">
        {messages.map((message) => {
          const messageId = message.id;
          console.log(messageId);
          return (
            <Box
              key={messageId}
              alignSelf={message.sender === "user" ? "end" : "start"}
              pad="small"
              background={message.sender === "user" ? "brand" : "light-3"}
              margin={{ vertical: "xsmall" }}
              round="small"
              style={{
                maxWidth: "70%", 
                wordWrap: "break-word", 
              }}
              flex={{ grow: 0, shrink: 0, basis: "auto" }}
            >
              <Box>{message.message}</Box>
              {message.sender === "bot" && (
                <Box
                  direction="column" 
                  align="start"
                  margin={{ top: "small" }}
                >
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    margin={{ bottom: "small" }} 
                  >
                    {message.answer_context && (
                      <Box direction="row" align="center">
                        <Tip content="Copy">
                          <Button
                            icon={
                              copiedMessageId === messageId ? (
                                <Clipboard size="medium" color="green" />
                              ) : (
                                <Clipboard size="medium" />
                              )
                            }
                            onClick={() =>
                              handleCopy(message.original_answer, messageId)
                            }
                          />
                        </Tip>
                        <Tip content="Source">
                          <Button
                            icon={
                              <ListIcon
                                className="context-button"
                                onClick={() => toggleSources(messageId)}
                              />
                            }
                          />
                        </Tip>
                      </Box>
                    )}
                  </Box>
                  {visibleSources[messageId] && (
                    <Box
                      background="light-6"
                      pad="small"
                      round="small"
                      margin={{ top: "small" }}
                    >
                      <Text weight="bold">Sources:</Text>
                      <List
                        data={message.answer_context} // Use the correct context data
                        primaryKey={(url, index) => (
                          <Anchor
                            className="anchor-style"
                            key={`${index}`} 
                            href={url}
                            label={url}
                            target="_blank" 
                            style={{
                              overflowWrap: "break-word",
                              whiteSpace: "normal",
                              width: "100%", 
                            }}
                          />
                        )}
                        pad="small"
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          );
        })}

        {loading && (
          <Box align="center" pad="medium">
            <Spinner size="large" />
          </Box>
        )}
      </Box>

      <Box direction="row" gap="small" align="center" margin="small">
        <Box fill direction="row" gap="small" align="center" margin="small">
          <TextArea
            placeholder="Ask your questions..."
            value={textAreaMessage}
            onChange={handleTextArea}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={loading}
            resize="vertical"
            fill
            style={{
              minHeight: "70px",
              maxHeight: "150px",
              overflowY: "auto",
            }}
          />
        </Box>

        <Tip content="Send">
          <Button
            icon={<Send size="large" />}
            onClick={handleSendMessage}
            primary
          />
        </Tip>

        {showNotification && sendingError && (
          <Notification
            toast
            status="warning"
            message={<Text size="large">{sendingError}</Text>}
            onClose={() => setShowNotification(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default ChatWindow;
