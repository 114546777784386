// import { grommet } from "grommet";
import { Box, Text } from "grommet";

const TableControls = ({ selected, data }) => {
  return (
    <Box
      direction="row"
      fill="horizontal"
      justify="between"
      pad={{ vertical: "small" }}
      flex={false}
    >
      <SelectionSummary selected={selected} data={data} />
    </Box>
  );
};

const SelectionSummary = ({ selected, data }) =>
  selected.length ? (
    <Text>
      {selected.length} of {data.length} items selected
    </Text>
  ) : (
    <Text>{data.length} items</Text>
  );

export default TableControls;
