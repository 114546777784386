import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Box,
  DataTable,
  Menu,
  ResponsiveContext,
  Text,
  TextInput,
  Select,
  DateInput,
  Button,
  Spinner,
} from "grommet";
import { Search as SearchIcon } from "grommet-icons";
import { Data, DataView, Toolbar } from "grommet";
import PaginationControls from "../components/Pagination";
import TableControls from "../components/TableControls";
import AnalysisModal from "../components/AnalysisModal";
import { useNavigate } from "react-router-dom";

export const ListView = () => {
  const size = useContext(ResponsiveContext);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedColumnType, setSelectedColumnType] = useState("");
  const [error, setError] = useState("");
  const [searchDateRange, setSearchDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Helper function to capitalize column names
  const capitalize = (str) =>
    str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

  // Helper function to format date strings
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}-${date.getFullYear()}`;
  };

  // Memoizing handleContractClick to avoid unnecessary re-creations
  const handleContractClick = useCallback(
    (contractId) => {
      navigate(`/contracts/${contractId}`);
    },
    [navigate]
  ); // Add navigate as a dependency

  // Fetching data with filters applied
  const fetchData = useCallback(
    async (params = {}) => {
      setLoading(true);
      const columnNameMapping = {
        customer_name: "End-customer",
        invoice_country: "Country",
      };
      const queryParams = new URLSearchParams({
        ...params,
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage,
      }).toString();
      const url = `https://d3cwadfonj.execute-api.us-west-2.amazonaws.com/dev/contracts${
        queryParams ? `?${queryParams}` : ""
      }`;

      try {
        const response = await fetch(url);
        const result = await response.json();
        const { results, columns: jsonColumns } = result;

        setTotalItems(results.length);

        // Check if 'contract_id' and 'contract_type' exist
        const contractIdColumn = jsonColumns.find(
          (col) => col.name === "contract_id"
        );
        const contractTypeColumn = jsonColumns.find(
          (col) => col.name === "contract_type"
        );

        // Filter out 'contract_id' and 'contract_type' from the remaining columns
        const remainingColumns = jsonColumns.filter(
          (col) =>
            col.name !== "contract_id" &&
            col.name !== "contract_type" &&
            col.name !== "contract_collection_name" &&
            col.name !== "total_count"
        );

        // Sort the remaining columns alphabetically by their name
        const sortedRemainingColumns = remainingColumns.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Separate 'issue_date' column from the sorted columns
        const issueDateColumn = sortedRemainingColumns.find(
          (col) => col.name === "issue_date"
        );
        const otherColumns = sortedRemainingColumns.filter(
          (col) => col.name !== "issue_date"
        );

        // Arrange the columns
        let finalColumns = [];
        if (contractIdColumn) finalColumns.push(contractIdColumn); // Add contract_id as first
        if (contractTypeColumn) finalColumns.push(contractTypeColumn); // Add contract_type as second
        finalColumns = [...finalColumns, ...otherColumns]; // Add the rest alphabetically
        if (issueDateColumn) finalColumns.push(issueDateColumn); // Add issue_date at the end

        // Generate columns based on fetched data
        const generatedColumns = finalColumns.map((col) => ({
          property: col.name,
          header: (
            <Text
              style={{
                width: "120px", // Equal width for all headers
                fontWeight: "bold",
                whiteSpace: "nowrap", // Prevent text wrapping
              }}
            >
              {columnNameMapping[col.name] || capitalize(col.name)}{" "}
              {/* Use the mapped name */}
            </Text>
          ),
          render: (datum) => {
            const value = datum[col.name];
            if (col.name === "contract_id") {
              return (
                <Button plain onClick={() => handleContractClick(value)}>
                  <Text color="brand" style={{ textDecoration: "underline" }}>
                    {value}
                  </Text>
                </Button>
              );
            }

            // Handle column type-based rendering
            if (col.type === "date") {
              return <Text>{formatDate(value)}</Text>;
            }

            return <Text>{value}</Text>;
          },
          align: "start",
          type: col.type,
        }));
        setData(results);
        setFilteredData(results);
        setColumns(generatedColumns);
      } catch (error) {
        console.error("Error fetching data from the URL:", error);
        setError("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    },
    [handleContractClick, currentPage, itemsPerPage]
  );

  // useEffect to fetch data whenever search text, selected column, or date range changes
  useEffect(() => {
    const params = {};
    if (selectedColumn && searchText) {
      params[selectedColumn] = searchText;
    }

    // Handle date range if applicable
    if (searchDateRange.length === 2) {
      // Use a specific format for the date range
      const startDate = formatDate(searchDateRange[0]);
      const endDate = formatDate(searchDateRange[1]);
      params[`issue_date`] = `${startDate}--${endDate}`; // Adjust this line
    }

    fetchData(params); 
  }, [
    searchText,
    selectedColumn,
    searchDateRange,
    currentPage,
    itemsPerPage,
    fetchData,
  ]);

  // Handle column selection
  const handleColumnSelect = (option) => {
    setSelectedColumn(option.value);
    const selectedCol = columns.find((col) => col.property === option.value);
    setSelectedColumnType(selectedCol ? selectedCol.type : "");
    setSearchText("");
    setSearchDateRange([]);
    setError("");
  };

  // Paginate filtered data
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle clearing filters
  const handleClearFilters = () => {
    setSelectedColumn(""); 
    setSearchText(""); 
    setSearchDateRange([]); 
    setError(""); 
    fetchData(); 
  };

  return (
    <Box fill>
      <Box>
        <Data data={data}>
          <Toolbar gap="medium">
            <Toolbar
              direction="row"
              justify="between"
              fill="horizontal"
              pad={{ vertical: "medium" }}
            >
              <Box
                direction="row"
                justify="between"
                pad={{ vertical: "small" }}
                gap="medium"
              >
                {/* Column select dropdown */}
                <Select
                  options={columns.map((col) => ({
                    label: capitalize(col.property),
                    value: col.property,
                  }))}
                  value={
                    selectedColumn
                      ? {
                          label: capitalize(selectedColumn),
                          value: selectedColumn,
                        }
                      : ""
                  }
                  onChange={({ option }) => handleColumnSelect(option)}
                  placeholder="Select a Column"
                />
              </Box>
              <Box
                direction="row"
                justify="between"
                pad={{ vertical: "small" }}
                gap="medium"
              >
                {selectedColumnType === "date" ||
                selectedColumnType === "timestamp without time zone" ? (
                  <DateInput
                    format="mm/dd/yyyy-mm/dd/yyyy"
                    value={searchDateRange}
                    onChange={({ value }) => setSearchDateRange(value)}
                    dropProps={{ align: { top: "bottom" } }}
                    placeholder="Select a date range"
                    range
                    style={{ width: "300px" }}
                  />
                ) : (
                  <TextInput
                    icon={<SearchIcon />}
                    placeholder={
                      selectedColumn
                        ? `Search in ${selectedColumn}`
                        : "Select column to search"
                    }
                    value={searchText}
                    onChange={({ target: { value } }) => setSearchText(value)}
                    style={{ width: "300px" }}
                    disabled={!selectedColumn}
                    type="search"
                  />
                )}
                {error && <Text color="status-critical">{error}</Text>}
              </Box>
              <Box
                direction="row"
                justify="between"
                pad={{ vertical: "small" }}
                gap="medium"
              >
                {/* Clear button */}
                <Button
                  label={<Text size="medium">Reset Search</Text>}
                  onClick={handleClearFilters}
                  disabled={
                    !selectedColumn &&
                    !searchText &&
                    searchDateRange.length === 0
                  }
                  secondary
                />
              </Box>

              <Box
                direction="row"
                justify="between"
                pad={{ vertical: "small" }}
                gap="medium"
                margin={{ left: "auto" }}
              >
                <Menu
                  label={<Text style={{ fontWeight: "bold" }}>Actions</Text>}
                  kind="toolbar"
                  items={[
                    {
                      label: "AI Analysis",
                      onClick: () => setShowModal(true), // Handle click event here
                    },
                  ]}
                  style={{
                    border: "2px solid rgb(1, 169, 130)",
                    borderRadius: "20px",
                    fontSize: "16px",
                  }}
                />
              </Box>
              {showModal && (
                <AnalysisModal
                  onClose={() => setShowModal(false)}
                  selectedContracts={selected} // Pass the selected contracts
                />
              )}
            </Toolbar>
            <DataView />
          </Toolbar>
          <TableControls selected={selected} data={paginatedData} />
          <Box fill align="center" justify="center">
            {loading ? (
              <Spinner size="large" /> // Improved loading indicator
            ) : (
              <DataTable
                aria-describedby="orders-heading"
                data={paginatedData}
                primaryKey="contract_id"
                columns={columns}
                pin={["xsmall", "small"].includes(size)}
                select={selected}
                onSelect={setSelected}
              />
            )}
          </Box>
          <PaginationControls
            currentPage={currentPage}
            totalItems={totalItems} // Use totalItems here
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            setItemsPerPage={setItemsPerPage}
          />
        </Data>
      </Box>
    </Box>
  );
};

export default ListView;
