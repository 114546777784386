import React, { useState } from "react";
import { Box, Button, Layer, Heading, Text, RadioButtonGroup, ResponsiveContext } from "grommet";
import ReportInfo from "./ReportInfo";
import ReportName from "./ReportName"; // Import the ReportName component

const AnalysisModal = ({ onClose, selectedContracts }) => {
  const [selectedAnalysis, setSelectedAnalysis] =
    useState("Deviation Analysis");
  const [showReportInfo, setShowReportInfo] = useState(false);
  const [showReportName, setShowReportName] = useState(false); // State to control showing ReportName
  const [showToast, setShowToast] = useState(false);

  const analysisOptions = [
    {
      label: "Deviation Analysis",
      description:
        "Visualizes key data and highlights deviations from templates",
    },
    {
      label: "Natural Language Processing",
      description: "Get key information and summarize with the help of AI",
    },
    {
      label: "Advanced Programmatic Prompt",
      description: "Write a specific prompt and get access to JSON files",
    },
  ];

  const handleRunAnalysis = () => {
    if (selectedContracts.length === 0) {
      setShowToast(true); // Show toast if no contracts are selected
      return;
    }

    if (
      selectedAnalysis === "Natural Language Processing" ||
      selectedAnalysis === "Advanced Programmatic Prompt"
    ) {
      setShowReportInfo(true); // Show the ReportInfo modal
    } else {
      setShowReportName(true); // Show the ReportName modal for Deviation Analysis
    }
  };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <>
          {!showReportInfo &&
            !showReportName && ( // Show the main modal if neither sub-modal is open
              <Layer
                onEsc={onClose}
                position="center"
                justify="center"
                align="center"
              >
                <Box pad="medium" gap="medium">
                  <Heading level={2} margin="none" weight="bold">
                    Define AI Analysis
                  </Heading>

                  <RadioButtonGroup
                    name="analysisType"
                    options={analysisOptions.map((option) => option.label)}
                    value={selectedAnalysis}
                    onChange={(event) =>
                      setSelectedAnalysis(event.target.value)
                    }
                  >
                    {(option, { checked }) => (
                      <Box
                        pad={{ vertical: "small", horizontal: "large" }}
                        border={{
                          color: checked ? "brand" : "light-4",
                          size: "medium",
                        }}
                        round="small"
                        direction="row"
                        align="center"
                        justify="center"
                        height="xsmall"
                        flex="grow"
                        margin={{ vertical: "small" }}
                        width="500px"
                      >
                        <Box align="center" justify="center">
                          <Text weight="bold">{option}</Text>
                          <Text size="small" color="dark-6">
                            {
                              analysisOptions.find(
                                ({ label }) => label === option
                              ).description
                            }
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </RadioButtonGroup>

                  <Box direction="row" gap="medium" justify="between">
                    <Button label="Cancel" onClick={onClose} />
                    <Button
                      label="Run Analysis"
                      primary
                      onClick={handleRunAnalysis}
                    />
                  </Box>

                  {showToast && (
                    <Text color="status-critical">
                      Please select at least one contract.
                    </Text>
                  )}
                </Box>
              </Layer>
            )}

          {showReportInfo && (
            <ReportInfo
              onClose={() => {
                setShowReportInfo(false); // Close ReportInfo
                onClose(); // Close the AnalysisModal
              }}
              selectedContracts={selectedContracts} // Pass selected contracts
              selectedAnalysis={selectedAnalysis} // Pass selected analysis type
            />
          )}

          {showReportName && (
            <ReportName
              onClose={() => {
                setShowReportName(false); // Close ReportName
                onClose(); // Close the AnalysisModal
              }}
              selectedContracts={selectedContracts} // Pass selected contracts to ReportName
            />
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};
export default AnalysisModal;
