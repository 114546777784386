import React, { useEffect, useState, useCallback, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Text,
  Box,
  Spinner,
  DataTable,
  Button,
  ResponsiveContext,
  Heading,
} from "grommet";
import ContractAnalysisDrawer from "./ReportModal"; // Import the modal
import FormatResponse from "../components/FormatResponse";

const AnalysisReport = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const size = useContext(ResponsiveContext);
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false); // State to control drawer visibility
  const [error, setError] = useState(null);
  const [contractCollectionName, setContractCollectionName] = useState("");
  const [chatHistory, setChatHistory] = useState([]); // State for chat history

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await fetch(
          `https://d3cwadfonj.execute-api.us-west-2.amazonaws.com/dev/contract-analysis/reports/${reportId}`
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Fetched report data:", data); // Log the entire response
        setReport(data);

        // Check if analysis_data and results exist
        if (
          data.analysis_data &&
          data.analysis_data.results &&
          data.analysis_data.results.length > 0
        ) {
          // Find the result with the matching contract_id
          const selectedContract = data.analysis_data.results.find(
            (result) => result.contract_id === selectedContractId // Ensure contractId is available in your component
          );

          if (selectedContract) {
            const collectionName = selectedContract.contract_collection_name; // Access the collection name
            setContractCollectionName(collectionName);
            console.log("Contract Collection Name set:", collectionName); // Log collection name
          } else {
            console.warn(
              "No matching contract_id found, using default collection name"
            );
            setContractCollectionName("default_collection_name"); // Use a default or handle accordingly
          }
        } else {
          console.warn(
            "No analysis results found, using default collection name"
          );
          setContractCollectionName("default_collection_name");
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
        setError("Failed to load report data.");
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [reportId, selectedContractId]); // Make sure to include contractId as a dependency

  // Update chat history whenever the selected contract ID changes
  useEffect(() => {
    if (report && selectedContractId) {
      const analysisRecords = report.analysis_records.find(
        (record) => record.contract_id === selectedContractId
      );
      if (analysisRecords) {
        const history = analysisRecords.analysis_ai_conversation_history || []; // Ensure this is an array
        setChatHistory(history); // Set chat history for the selected contract
        console.log("Chat History set:", history); // Log chat history
      }
    }
  }, [selectedContractId, report]);

  const handleContractIdClick = useCallback(
    (contractId) => {
      setSelectedContractId(contractId);
      setShowDrawer(true);

      // Find the specific record and set the collection name
      const selectedRecord = report.analysis_records.find(
        (record) => record.contract_id === contractId
      );
      if (selectedRecord) {
        setContractCollectionName(selectedRecord.contract_collection_name);
        console.log(
          "Updated Contract Collection Name:",
          selectedRecord.contract_collection_name
        );
      }
    },
    [report]
  );

  const handleCloseDrawer = () => setShowDrawer(false); // Close the drawer

  if (loading) {
    return (
      <Box align="center" justify="center" pad="large">
        <Spinner size="large" />
        <Text>Loading...</Text>
      </Box>
    );
  }

  if (error) {
    return <Text color="status-critical">{error}</Text>;
  }

  if (!report) {
    return <Text>No report data available.</Text>;
  }

  // Utility function to capitalize text and remove underscores
  const capitalize = (str) =>
    str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

  const contractIdColumn = {
    property: "contract_id",
    header: (
      <Text
        style={{ width: "120px", fontWeight: "bold", whiteSpace: "nowrap" }}
      >
        Contract ID
      </Text>
    ),
    align: "start",
    render: (datum) => (
      <Text
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() => handleContractIdClick(datum.contract_id)}
      >
        {datum.contract_id}
      </Text>
    ),
  };

  const columns = report.analysis_data.columns.reduce(
    (acc, column) => {
      // Only add columns that are not contract_collection_name
      if (
        column.name !== "contract_id" &&
        column.name !== "contract_collection_name"
      ) {
        acc.push({
          property: column.name,
          header: (
            <Text
              style={{
                width: "120px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {capitalize(column.name)}
            </Text>
          ),
          align: "start",
        });
      }
      return acc;
    },
    [contractIdColumn] // Keep the contractIdColumn as is
  );

  const data = report.analysis_data.results.map((result) => {
    // Create a new object without the contract_collection_name
    const { contract_collection_name, ...rest } = result;
    return rest; // Return the rest of the object without the contract_collection_name
  });

  const handleBackButtonClick = () => {
    console.log("Navigating back to Reports tab...");
    navigate("/", { state: { activeTab: 1 } }); // Set to Reports tab
  };

  return (
    <Box pad="medium">
      <Box align="start" pad={{ bottom: "small" }}>
        <Button
          label="Back To Reports"
          onClick={handleBackButtonClick}
          secondary
        />
      </Box>
      <Box
        border={{
          side: "bottom",
          color: "rgb(1, 169, 130)",
          borderRadius: "medium",
          size: "medium",
        }}
        margin={{ bottom: "small" }}
      >
        <Box
          margin="small"
          flex={false}
          direction="row"
          justify="between"
          align="center"
        >
          <Heading
            level={2}
            size="medium"
            style={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            Report Summary
          </Heading>
        </Box>
        <Box direction="row" gap="" justify="between" height="40vh">
          {/* Right side with Analysis Summary */}
          <Box
            margin={{ left: "small" }}
            gap="small"
            align="start"
            flex={false}
            width="60%"
          >
            <Text size="large" weight="bold">
              Analysis Summary:
            </Text>
            <Box overflow={{ vertical: "auto" }}>
              <Text textAlign="start" margin={{ bottom: "medium" }}>
                {FormatResponse(report.analysis_summary)}
              </Text>
            </Box>
          </Box>

          {/* Left side with Analysis Type and Input */}
          <Box
            margin={{ right: "small", left:"medium"}}
            align="start"
            gap="small"
            flex="grow"
            width="40%"
            // overflow="auto"
            // fill ="vertical"
          >
            <Box direction="row" align="center" gap="small">
              <Text size="large" weight="bold">
                Analysis Type:
              </Text>
              <Text textAlign="start">
                {FormatResponse(report.analysis_type)}
              </Text>
            </Box>

            {report.analysis_question && (
              <Box direction="row" align="center" gap="small">
                <Text size="large" weight="bold">
                  Input:
                </Text>
                <Box overflow={{ vertical: "auto" }}>
                  <Text textAlign="start">
                    {FormatResponse(report.analysis_question)}
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box margin={{ top: "medium" }} overflow="auto">
        <DataTable data={data} columns={columns} primaryKey="contract_id" />
      </Box>

      {showDrawer && selectedContractId && (
        <ContractAnalysisDrawer
          onClose={handleCloseDrawer}
          contractId={selectedContractId}
          analysisResult={
            report.analysis_records.find(
              (record) => record.contract_id === selectedContractId
            )?.analysis_result
          }
          analysisSources={
            report.analysis_records.find(
              (record) => record.contract_id === selectedContractId
            )?.analysis_sources
          }
          contractCollectionName={contractCollectionName} // Keep this for backend use
          chatHistory={chatHistory} // Pass the chat history
          size={size}
        />
      )}
    </Box>
  );
};

export default AnalysisReport;
