import React from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Grommet, Box, ResponsiveContext} from "grommet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Navbar";
import { hpe } from "grommet-theme-hpe";
import HomePage from "./views/HomePage";
import ReportSummary from "./views/ReportSummary";
import ContractDetails from "./views/ContractDetails";

Amplify.configure(awsconfig);

function App() {
  // Check if the header should be hidden based on URL parameters
  const hideHeader =
    new URLSearchParams(window.location.search).get("header") === "false";

    return (
      <Grommet theme={hpe} full>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box className="App">
              {/* Conditionally render the Header */}
              {!hideHeader && size !== 'small' && <Header />} {/* Show Header only for larger screens */}
              <Router>
                <Routes>
                  <Route path="/search" element={<HomePage />} />
                  <Route path="/reports" element={<HomePage />} />
                  <Route path="/" element={<HomePage />} /> {/* Default route */}
                  <Route path="/report/:reportId" element={<ReportSummary />} />
                  <Route path="/contracts/:contractId" element={<ContractDetails />} />
                  {/* Route to ReportSummary */}
                </Routes>
              </Router>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    );
  }

export default withAuthenticator(App);
