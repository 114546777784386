import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Text, Heading, Spinner, Button } from "grommet";
import ChatWindow from "../components/ChatWindow";

// Helper function to format field names
const formatFieldName = (fieldName) => {
  return fieldName
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

const ContractDetails = () => {
  const { contractId } = useParams(); // Retrieve contract_id from the URL
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [contractCollectionName, setContractCollectionName] = useState("");
  const [chatHistory, setChatHistory] = useState("");
  const scrollableDiv = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch contract details from API
    const fetchContractDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://d3cwadfonj.execute-api.us-west-2.amazonaws.com/dev/contracts/${contractId}`
        );
        const data = await response.json();

        if (data.contract) {
          setContract(data.contract);
          setContractCollectionName(
            data.contract.contract_collection_name || ""
          );
          setChatHistory(data.contract.chat_history || ""); // Default to an empty string if chatHistory is not present
        }
      } catch (error) {
        setError("Failed to load contract details.");
      } finally {
        setLoading(false);
      }
    };

    fetchContractDetails();
  }, [contractId]);

  if (loading) {
    return (
      <Box align="center" justify="center" fill>
        <Spinner size="large" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box align="center" justify="center" fill>
        <Text color="status-critical">{error}</Text>
      </Box>
    );
  }

  const handleBackButtonClick = () => {
    console.log("Navigating back to search tab...");
    navigate("/"); // Set to Reports tab
  };

  return (
    <Box fill>
      <Box align="start" pad={{top:"small", left:"medium"}}>
        <Button
          label="Back To Search page"
          onClick={handleBackButtonClick}
          secondary
        />
      </Box>
      <Box
        fill
        className="chat-window-container"
        direction="row"
        pad={{top:"small", right:"medium", left:"medium"}}
        gap="small"
        style={{ height: "80vh", width: "100vw" }}

      >
        <Box
          width="large"
          background="#5e5d5d"
          pad="xsmall"
          overflow="auto"
          round="small"
          flex="Fixed"
        >
          <Box align="start" ref={scrollableDiv} pad="small">
            <Heading
              level={2}
              margin="none"
              style={{ textDecoration: "underline", fontWeight: "bold" }}
            >
              Contract Details
            </Heading>
          </Box>
          <Box align="start" pad="medium">
            {contract ? (
              Object.entries(contract).map(([key, value]) => (
                <Text key={key}>
                  <strong>{formatFieldName(key)}:</strong> {value || "N/A"}
                </Text>
              ))
            ) : (
              <Text>No contract details available.</Text>
            )}
          </Box>
        </Box>

        <Box
          width="xlarge"
          background="light-1"
          direction="column"
          round="small"
          overflow="auto"
          style={{background:"aqua"}}
          fill
        >
          <ChatWindow
            contractCollectionName={contractCollectionName}
            chatHistory={chatHistory}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContractDetails;
