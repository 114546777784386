import React, { useState } from "react";
import { Box, Button, Layer, Heading, Text, TextInput } from "grommet";

const ReportName = ({ onClose, selectedContracts }) => {
  const [reportName, setReportName] = useState("");

  const handleRunAnalysis = async () => {
    console.log("Selected Contract IDs:", selectedContracts);
    console.log("Report Name:", reportName);

    const requestData = {
      contract_ids: selectedContracts,
      analysis_type: "DEVIATION", // Fixed analysis type for deviation
      analysis_name: reportName,
      analysis_question: undefined, // No question needed for deviation analysis
    };

    try {
      const response = await fetch(
        "https://d3cwadfonj.execute-api.us-west-2.amazonaws.com/dev/contract-analysis/tasks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Analysis Task Created:", result);

      onClose();
    } catch (error) {
      console.error("Failed to run analysis:", error);
    }
  };

  return (
    <Layer onEsc={onClose}>
      <Box pad="medium" gap="medium" width="large">
        <Heading level={3} margin="none" weight="bold" pad="medium">
          Deviation Analysis
        </Heading>

        <Box>
          <Text margin={{ bottom: "small" }}>Report Name</Text>
          <TextInput
            value={reportName}
            onChange={(event) => setReportName(event.target.value)}
            placeholder="Enter report name"
          />
        </Box>

        <Box
          direction="row"
          gap="medium"
          justify="between"
          margin={{ top: "medium" }}
        >
          <Button label="Cancel" onClick={onClose} />
          <Button label="Run Analysis" primary onClick={handleRunAnalysis} />
        </Box>
      </Box>
    </Layer>
  );
};

export default ReportName;
