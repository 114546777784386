import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Text } from "grommet";
import ListView from "./SearchPage";
import Reports from "./Reports";

// Define constants for tab names
const TAB_SEARCH = 0; // Index for Search tab

export const HomePage = () => {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = sessionStorage.getItem("activeTab");
    return savedTab !== null ? Number(savedTab) : TAB_SEARCH;
  });

  // Update sessionStorage whenever activeTab changes
  useEffect(() => {
    sessionStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  // Update active tab on tab change
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Box pad={{ top: "large", bottom: "medium", left: "large", right: "large" }}>
      <Tabs activeIndex={activeTab} onActive={handleTabChange} flex={false} justify="start">
        <Tab title={<Text size="large" weight="bold">Search</Text>}>
          <ListView />
        </Tab>
        <Tab title={<Text size="large" weight="bold">Reports</Text>}>
          <Reports />
        </Tab>
      </Tabs>
    </Box>
  );
};

export default HomePage;
